const paths = {
  not_found: "/not-found",
  login: "/login",
  forgot_password: "/esqueci-a-senha",
  home: "/home",
  prescriptions: {
    list: "/receitas",
  },
  medical_records: {
    list: "/prontuarios",
  },
  contents: {
    list: "/conteudos",
    add: "/conteudos/adicionar",
    edit: "/conteudos/editar",
    detail: "/conteudos/detalhes",
    categories: {
      list: "/conteudos/categorias",
    },
    subcategories: {
      list: "/conteudos/subcategoria",
    },
  },
  users: {
    list: "/usuarios",
    detail: "/usuarios/detalhes",
  },
  patients: {
    list: "/pacientes",
    detail: "/pacientes/detalhes",
  },
  admins: {
    list: "/administradores",
    add: "/administradores/adicionar",
    edit: "/administradores/editar",
    detail: "/administradores/detalhes",
    password_update: "/administradores/editar-senha",
  },
  medicines: {
    list: "/medicamentos",
    add: "/medicamentos/adicionar",
    edit: "/medicamentos/editar",
    detail: "/medicamentos/detalhes",
  },
  calculators: {
    list: "/calculadoras",
  },
  scores: {
    list: "/escores",
  },
  settings: {
    edit: "/configuracoes/editar",
    view: "/configuracoes",
  },
  crashes: {
    list: "/crashes/",
    detail: "/crashes/detalhes",
  },
  aiFiles: {
    list: "/arquivos-da-ia",
  },
  sendNotification: "/enviar-notificacao",
};

export default paths;

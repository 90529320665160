import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      columns: [
        {
          name: "Substância",
          sortName: null,
          order: null,
        },
        {
          name: "Classe terapêutica",
          sortName: null,
          order: null,
        },
        {
          name: "Nome comercial",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      // return this.$medicinesService.listMedicineNames({
      return this.$medicinesService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    prepareResponse(data) {
      // return data;
      return data.map((elem) => ({
        ...elem,
        names: elem.names
          .map((n) => n.name)
          .join(", ")
          .limitString(60),
        compound: elem.compound.limitString(60),
        // treatment_methods: elem.treatment_methods.limitString(60),
      }));
    },
  },
};

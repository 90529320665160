import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
    name: 'Calculators',
    mixins: [ListControllerMixin],
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            columns: [
                {
                    name: 'Nome do item',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Data',
                    sortName: null,
                    order: null
                },
            ],
        };
    },
    methods: {
        fetchPromise() {
            return this.$contentsService.listCalculatorTypes({
                ...this.filters,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        },
    }
};
import store from "@/store";
import AddAdmins from "@/views/Admins/AddAdmins/AddAdmins.vue";
import AdminDetail from "@/views/Admins/AdminDetail/AdminDetail.vue";
import AdminPasswordUpdate from "@/views/Admins/AdminPasswordUpdate/AdminPasswordUpdate.vue";
import Admins from "@/views/Admins/Admins.vue";
import EditAdmins from "@/views/Admins/EditAdmins/EditAdmins.vue";
import AddContent from "@/views/Contents/AddContent/AddContent.vue";
import EditContent from "@/views/Contents/EditContent/EditContent.vue";
import Categories from "@/views/Contents/Categories/Categories.vue";
import Subcategories from "@/views/Contents/Subcategories/Subcategories.vue";
import ContentDetail from "@/views/Contents/ContentDetail/ContentDetail.vue";
import Contents from "@/views/Contents/Contents.vue";
import SettingsEdit from "@/views/Settings/SettingsEdit/SettingsEdit.vue";
import Settings from "@/views/Settings/Settings.vue";
import Crashes from "@/views/Crashes/Crashes.vue";
import SendNotification from "@/views/SendNotification/SendNotification.vue";
import CrashDetail from "@/views/Crashes/CrashDetail/CrashDetail.vue";
import ForgotPassword from "@/views/ForgotPassword/ForgotPassword.vue";
import Home from "@/views/Home/Home.vue";
import Login from "@/views/Login/Login.vue";
import MedicalRecords from "@/views/MedicalRecords/MedicalRecords.vue";
import NotFound from "@/views/NotFound/NotFound.vue";
import PatientDetail from "@/views/Patients/PatientDetail/PatientDetail.vue";
import Patients from "@/views/Patients/Patients.vue";
import Prescriptions from "@/views/Prescriptions/Prescriptions.vue";
import Calculators from "@/views/Calculators/Calculators.vue";
import Medicines from "@/views/Medicines/Medicines.vue";
import AddMedicines from "@/views/Medicines/AddMedicines/AddMedicines.vue";
import MedicineDetail from "@/views/Medicines/MedicineDetail/MedicineDetail.vue";
import Scores from "@/views/Scores/Scores.vue";
import AIFiles from "@/views/AIFiles/AIFiles.vue";
import UserDetail from "@/views/Users/UserDetail/UserDetail.vue";
import Users from "@/views/Users/Users.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import paths from "./paths";

Vue.use(VueRouter);

function isLogged() {
  return store.state.userSession ? true : false;
}

function authCheck(to, from, next) {
  if (isLogged()) {
    next({ path: to });
  } else {
    next({ path: paths.login });
  }
}

const routes = [
  {
    path: "/",
    redirect: {
      name: "Home",
    },
  },

  // LOGIN
  {
    path: paths.login,
    name: "Login",
    component: Login,
    props: true,
  },

  // ESQUECI MINHA SENHA
  {
    path: paths.forgot_password,
    name: "Esqueci a senha",
    component: ForgotPassword,
    props: true,
  },

  // HOME
  {
    path: paths.home,
    name: "Home",
    component: Home,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE RECEITAS
  {
    path: paths.prescriptions.list,
    name: "Receitas",
    component: Prescriptions,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE PRONTUÁRIOS
  {
    path: paths.medical_records.list,
    name: "Prontuários",
    component: MedicalRecords,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE USUÁRIOS
  {
    path: paths.users.list,
    name: "Usuários",
    component: Users,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE PACIENTES
  {
    path: paths.patients.list,
    name: "Pacientes",
    component: Patients,
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE DO USUÁRIO
  {
    path: `${paths.users.detail}/:id`,
    name: "Detalhes do Usuário",
    component: UserDetail,
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE DO PACIENTE
  {
    path: `${paths.patients.detail}/:id`,
    name: "Detalhes do Paciente",
    component: PatientDetail,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE ADMINS
  {
    path: paths.admins.list,
    name: "Administradores",
    component: Admins,
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE DO ADMIN
  {
    path: `${paths.admins.detail}/:id`,
    name: "Detalhe do Administrador",
    component: AdminDetail,
    props: true,
    beforeEnter: authCheck,
  },

  // ALTERAR SENHA DO ADMIN
  {
    path: `${paths.admins.password_update}/:id`,
    name: "Editar Senha de Administrador",
    component: AdminPasswordUpdate,
    props: true,
    beforeEnter: authCheck,
  },

  // EDITAR ADMIN
  {
    path: `${paths.admins.edit}/:id`,
    name: "Editar Administrador",
    component: EditAdmins,
    props: true,
    beforeEnter: authCheck,
  },

  // ADICIONAR ADMIN
  {
    path: `${paths.admins.add}`,
    name: "Adicionar Administrador",
    component: AddAdmins,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE SUBCATEGORIAS DE UMA (SUB)CATEGORIA
  {
    path: `${paths.contents.subcategories.list}/:id`,
    name: "Lista de subcategorias de uma (sub)categoria",
    component: Subcategories,
    props: true,
    beforeEnter: authCheck,
  },

  //  LISTA DE CATEGORIAS FIXAS DE CADA ÁREA DE CONTEÚDOS
  {
    path: paths.contents.categories.list,
    name: "List de categorias fixas de cada área de conteúdos",
    component: Categories,
    props: true,
    beforeEnter: authCheck,
  },

  // CRIAR CONTEÚDO
  {
    path: paths.contents.add,
    name: "Adicionar Conteúdo",
    component: AddContent,
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHES DO CONTEÚDO
  {
    path: `${paths.contents.detail}/:id`,
    name: "Detalhes do Conteúdo",
    component: ContentDetail,
    props: true,
    beforeEnter: authCheck,
  },

  // EDITAR CONTEÚDO
  {
    path: `${paths.contents.edit}/:id`,
    name: "Editar Conteúdo",
    component: EditContent,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE CONTEUDOS DE UMA (SUB)CATEGORIA
  {
    path: `${paths.contents.list}/:id`,
    name: "Lista de conteúdos de uma categoria",
    component: Contents,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE MEDICAMENTOS
  {
    path: `${paths.medicines.list}`,
    name: "Lista de medicamentos",
    component: Medicines,
    props: true,
    beforeEnter: authCheck,
  },

  // DETALHE DO MEDICAMENTO
  {
    path: `${paths.medicines.detail}/:id`,
    name: "Detalhe do medicamento",
    component: MedicineDetail,
    props: true,
    beforeEnter: authCheck,
  },

  // ADICIONAR MEDICAMENTOS
  {
    path: `${paths.medicines.add}`,
    name: "Adicionar medicamentos",
    component: AddMedicines,
    props: true,
    beforeEnter: authCheck,
  },

  // EDITAR MEDICAMENTOS
  {
    path: `${paths.medicines.edit}/:id`,
    name: "Editar medicamentos",
    component: AddMedicines,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE CALCULADORAS
  {
    path: `${paths.calculators.list}`,
    name: "Lista de calculadoras",
    component: Calculators,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE ESCORES
  {
    path: `${paths.scores.list}`,
    name: "Lista de escores",
    component: Scores,
    props: true,
    beforeEnter: authCheck,
  },

  // LISTA DE AI LEARNING
  {
    path: `${paths.aiFiles.list}`,
    name: "Lista de arquivos da IA",
    component: AIFiles,
    props: true,
    beforeEnter: authCheck,
  },

  // EDIT CONFIGURAÇÕES
  {
    path: `${paths.settings.edit}/:key`,
    name: "Editar Configurações",
    component: SettingsEdit,
    props: true,
    beforeEnter: authCheck,
  },

  // VIEW CONFIGURAÇÕES
  {
    path: `${paths.settings.view}/:key`,
    name: "Visualizar Configurações",
    component: Settings,
    props: true,
  },

  // CRASHES
  {
    path: `${paths.crashes.list}`,
    name: "Listar Crashes",
    component: Crashes,
    props: true,
  },
  {
    path: `${paths.crashes.detail}/:id`,
    name: "Visualizar Crashes",
    component: CrashDetail,
    props: true,
  },

  // SEND NOTIFICATIONS
  {
    path: paths.sendNotification,
    name: "SendNotification",
    component: SendNotification,
    props: true,
  },

  {
    path: "*",
    redirect: {
      name: "NotFound",
    },
  },
  {
    path: paths.not_found,
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  paths,
});

export default router;

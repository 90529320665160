import { NetworkService } from "./NetworkService";

export default class ContentsService {
  constructor() {
    this.network = new NetworkService();
  }
  create = (params) => {
    let p = { ...params, only_subscribers: params.only_subscribers ? 1 : 0 };
    return this.network.postMultipart(this.network.endpoints.contents, p);
  };
  list = async (params) => {
    let res = await this.network.get(this.network.endpoints.contents, params);
    return {
      ...res,
      data: res.data.map((elem, index) => {
        let description = "---";
        if (elem.description) {
          description = elem.description.substr(0, 100);
          description = description > 100 ? `${description}...` : description;
        }
        return { ...elem, description };
      }),
    };
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.contents}/${id}`);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.contents}/${id}`);
  };
  update = (id, params) => {
    let p = { ...params, only_subscribers: params.only_subscribers ? 1 : 0 };
    return this.network.put(`${this.network.endpoints.contents}/${id}`, p);
  };
  listAreas = () => {
    return this.network.get(this.network.endpoints.areas);
  };
  createCategory = (params) => {
    return this.network.post(this.network.endpoints.categories, params);
  };
  listCategories = async (params) => {
    let res = await this.network.get(this.network.endpoints.categories, params);
    return {
      ...res,
      data: res.data.map((elem) => {
        let count = elem.content_count + elem.count_subcategory;
        count = count == 1 ? `${count} item` : `${count} itens`;
        return { ...elem, count };
      }),
    };
  };
  showCategory = (id) => {
    return this.network.get(`${this.network.endpoints.categories}/${id}`);
  };
  deleteCategory = (id) => {
    return this.network.delete(`${this.network.endpoints.categories}/${id}`);
  };
  updateCategory = (id, params) => {
    return this.network.put(
      `${this.network.endpoints.categories}/${id}`,
      params
    );
  };
  createContentItems = (contentId, items, originalItemsLength) => {
    let promises = [];
    items.map((elem, index) => {
      let params = {
        ...elem,
        content_id: contentId,
        short: elem.short ? 1 : 0,
        // order: originalItemsLength + index + 1
        order: elem.order ? elem.order : originalItemsLength + index + 1,
      };
      console.log(params);
      promises.push(
        this.network.post(
          this.network.endpoints.contentItems,
          params,
          "MULTIPART"
        )
      );
    });
    return Promise.all(promises);
  };
  updateContentItemsOrder(contentId, items) {
    let promises = [];
    items.map((elem, index) => {
      let params = {
        content_id: contentId,
        order: elem.order ? elem.order : index + 1,
      };
      if (elem.id) {
        promises.push(
          this.network.post(
            `${this.network.endpoints.contentItems}/update/${elem.id}`,
            params,
            "MULTIPART"
          )
        );
      } else {
        params = { ...elem, ...params };
        promises.push(
          this.network.post(
            this.network.endpoints.contentItems,
            params,
            "MULTIPART"
          )
        );
      }
    });
    return Promise.all(promises);
  }
  deleteContentItem = (id) => {
    return this.network.delete(`${this.network.endpoints.contentItems}/${id}`);
  };
  updateContentItem = (id, params) => {
    let p = {
      ...params,
      short: params.short ? 1 : 0,
      description: params.description || "",
    };
    return this.network.post(
      `${this.network.endpoints.contentItems}/update/${id}`,
      p,
      "MULTIPART"
    );
  };
  listCalculatorTypes = () => {
    return this.network.get(`${this.network.endpoints.calculatorTypes}`);
  };
  listTabs = () => {
    return this.network.get(this.network.endpoints.contentItemsTabs);
  };
  calculatorTypes = {
    "deficiencia-de-ferro": {
      name: "Eletrólitos séricos - Deficiência de ferro",
    },
    "osmolaridade-plasmatica": {
      name: "Eletrólitos séricos - Osmolaridade plasmática",
    },
    "correcao-de-sodio-por-glicose": {
      name: "Eletrólitos séricos - Correção de sódio por glicose",
    },
    "correcao-de-calcio-por-albumina": {
      name: "Eletrólitos séricos - Correção de cálcio por albumina",
    },
    "mcg-kg-min-para-ml-h": {
      name: "Bomba de infusão - mcg/kg/min para ml/h",
    },
    "ml-h-para-mcg-kg-min": {
      name: "Bomba de infusão - ml/h para mcg/kg/min",
    },
    "gotas-min-para-ml-h": {
      name: "Bomba de infusão - Gotas/min para ml/h",
    },
    "anion-gap": {
      name: "Função renal - Ânion Gap",
    },
    "creatinina-ckd-epi": {
      name: "Função renal - Creatinina (CKD-EPI)",
    },
    "creatinina-mdrd-simplificada": {
      name: "Função renal - Creatinina (MDRD) simplificada",
    },
    "creatinina-cockroft-gault": {
      name: "Função renal - Creatinina Cockroft-Gault",
    },
    "escore-de-maddrey": {
      name: "Escore de maddrey",
    },
    "função-renal": {
      name: "Função renal",
    },
    "qt-corrigido": {
      name: "QT corrigido",
    },
    "eletrolitos-sericos": {
      name: "Eletrólitos séricos",
    },
    "gradiente-albumina-soro-ascite-gasa": {
      name: "Gradiente albumina soro-ascite (GASA)",
    },
    obstetricas: {
      name: "Obstétricas",
    },
    "pressao-arterial-media": {
      name: "Pressão Arterial Média",
    },
    imc: {
      name: "IMC",
    },
    "bomba-de-infusao": {
      name: "Bomba de Infusão",
    },
  };
}

<template>
  <div id="content_detail">
    <Navbar v-if="loadingItems" current="Detalhe do conteúdo" :link="$paths.contents.categories.list"
      :linkName="'Conteúdos'" />
    <Navbar v-else current="Detalhe do conteúdo" :link="$paths.contents.categories.list" :linkName="'Conteúdos'" :sublink="parentCategory?.count_subcategory
      ? `${$paths.contents.subcategories.list}/${parentCategory?.id}`
      : `${$paths.contents.list}/${parentCategory?.id}`
      " :sublinkName="parentCategory?.name" />
    <Sidebar />
    <Container>
      <Header title="Detalhe do conteúdo"
        :btnLink="`${$paths.contents.edit}/${id}?content_category_id=${parentCategory?.id}`" btnLabel="Editar"
        btnIcon="fa-solid fa-pen-to-square">
        <template v-slot:button>
          <button v-if="copied" class="btn btn-outline-success me-3">
            <span class="fas fa-copy"></span> Copiado!
          </button>
          <button v-else @click="copyURL" class="btn btn-outline-primary me-3">
            <span class="fas fa-copy"></span> Copiar URL
          </button>
          <button @click="handleDelete" class="btn btn-del lift ms-3">
            <div class="fa-solid fa-times-circle me-1"></div>
            Remover
          </button>
        </template>
      </Header>

      <div class="card">
        <LoadingContainer v-if="loadingItems" />
        <div class="card-body" v-else>
          <h2 class="text-header">{{ item.name }}</h2>
          <!-- <p>{{ item.description }}</p> -->
        </div>

        <div v-for="(content, index) in item.items" :key="`content_${index}`">
          <hr class="my-0" />
          <div class="card-body">
            <h2 v-if="content.name" class="text-header">
              {{ content.name }}
            </h2>

            <div v-if="content.type == 'html'" v-html="content.description" class="ql-editor"></div>

            <p v-if="content.description && content.type !== 'html'">
              {{ content.description }}
            </p>

            <!-- IMAGE -->
            <img :src="$baseImageUrl + content.url" v-if="content?.type == 'image'" class="img-thumbnail" />

            <!-- VIDEO -->
            <iframe v-if="content?.type == 'video'" class="mb-2" width="100%" height="500" :src="content.url"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen style="border-radius: 15px"></iframe>

            <!-- CALCULATOR -->
            <p v-if="content.type == 'calculator'">
              <strong>Formula:</strong>
              {{ $contentsService.calculatorTypes[content.calculator_type].name }}
            </p>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ContentDetailController from "./ContentDetailController";
export default ContentDetailController;
</script>
